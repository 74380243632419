export default {

  data() {
    return { productsLoading: false }
  },
  computed: {
    products() {
      return this.$store.state.products.data
    }
  },


  methods: {
    async getProducts() {
      this.productsLoading = true

      try {
        const { data } = await this.$api.get('/products')
        this.$store.commit('products/set', data.result.data)
      } catch (err) {
        console.log(err)
      }

      this.productsLoading = false
    },

    addProduct(product) {
      this.$store.commit('products/add', product)
    },
    updateProduct(product) {
      this.$store.commit('products/update', product)
    },
    deleteProduct(id) {
      this.$store.commit('products/delete', id)
    }
  },


  mounted() {
    if ( !this.products || this.products.length === 0 ) {
      this.getProducts() 
    }
  }
}