<template lang="pug">
.page.page-with-table.products-page
  .products-page-header
    .products-page-header__title Товары
    query-search.products-page-header-search
    it-button.products-page-header__create-btn(v-if="isAdmin" type="primary" @click="dialog = true") Создать продукт


  data-table.page__table.products-page__table(
    :loading="productsLoading" :headers="headers" :items="filteredProducts" defaultSortFilter="sort" modelName="product"
    :editApiMethod="editApiMethod" :editApiUrl="editApiUrl" @update:item="updateProduct"
    :deleteApiMethod="deleteApiMethod" :deleteApiUrl="deleteApiUrl" @delete:item="deleteProduct"
  )
  product-form-modal(v-if="isAdmin" v-model="dialog" @product:create="addProduct")
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import QuerySearch from '@/components/QuerySearch'
import DataTable from '@/components/DataTable'
import ProductFormModal from '@/components/ProductFormModal'
import productsMixin from '@/mixins/products'
import productTypes from '@/assets/productTypes'


export default {
  name: 'ProductsPage',
  components: { QuerySearch, DataTable, ProductFormModal },
  mixins: [productsMixin],
  data() {
    return {
      headers: [
        {title: 'id', value: 'id', readonly: true, sortable: true},
        {title: 'iid', value: 'iid', sortable: true},
        {title: 'Name', value: 'name', sortable: true},
        {title: 'Sort', value: 'sort', sortable: true},
        {title: 'Тип', value: 'type', inputType: 'select', options: productTypes, sortable: true},
        {title: 'Company', value: 'company', sortable: true},
        {title: 'Category', value: 'category', sortable: true},
        {title: 'Sku group', value: 'skugroup', sortable: true},
        {title: 'Price', value: 'price', sortable: true},
        {title: 'isNew', value: 'isNew', boolean: true, sortable: true},
        {title: 'Active', value: 'active', boolean: true}
      ],

      query: null,

      editApiUrl: '/products',
      editApiMethod: 'patch',
      deleteApiUrl: '/products',
      deleteApiMethod: 'delete',

      dialog: false
    }
  },
  computed: {
    filteredProducts() {
      if ( !this.queryReq || this.queryReq === '' ) return this.products
      return this.filterByQuery(this.products)
    }
  },

  watch: {
    queryReq: {
      handler() {
        this.query = this.queryReq
      },
      immediate: true
    }
  }
}
</script>