export default {
  iid: null,
  name: null,
  sort: null,
  company: null,
  category: null,
  skugroup: null,
  price: null,
  active: true,
  type: 'pack',
  isNew: false
}