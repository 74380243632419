<template lang="pug">
it-modal(v-model="modelValue")
  template(#header) 
    .product-form-modal__header Создать продукт

  template(#body)
    form.product-form-modal__body(id="product" @submit.prevent="submit")
      label.product-form-modal__label
        span.product-form-modal__label-title iid
        it-input(v-model="product.iid" required)
      label.product-form-modal__label
        span.product-form-modal__label-title name
        it-input(v-model="product.name" required)
      label.product-form-modal__label
        span.product-form-modal__label-title sort
        it-input(v-model="product.sort" type="number")
      label.product-form-modal__label
        span.product-form-modal__label-title type
        it-select(v-model="product.type" :options="productTypes" placeholder="Выберите тип")
      label.product-form-modal__label
        span.product-form-modal__label-title company
        it-input(v-model="product.company")
      label.product-form-modal__label
        span.product-form-modal__label-title category
        it-input(v-model="product.category")
      label.product-form-modal__label
        span.product-form-modal__label-title sku group
        it-input(v-model="product.skugroup")
      label.product-form-modal__label
        span.product-form-modal__label-title price
        it-input(v-model="product.price" type="number" required)


  template(#actions)
    it-button(type="danger" outlined @click="$emit('update:modelValue', false)") Отменить
    it-button(type="primary" form="product" :loading="loading") Создать

</template>

<style lang="sass" src="./index.sass"></style>


<script>
import emptyProduct from '@/assets/emptyProduct'
import productTypes from '@/assets/productTypes'


export default {
  name: 'ProductFormModal',
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue', 'product:create'],
  data() {
    return {

      loading: false,
      product: {...emptyProduct},
      productTypes: productTypes

    }
  },
  watch: {
    modelValue() {
      this.$emit('update:modelValue', this.modelValue)
      if ( !this.modelValue ) this.product = {...emptyProduct}
    }
  },

  methods: {
    async submit() {
      if ( this.loading ) return
      this.loading = true

      try {
        const {data} = await this.$api.post('/products', {product: this.product})
        this.$notification.success('Продукт создан')
        this.$emit('update:modelValue', false)
        this.$emit('product:create', data.result.product)
      } catch (err) {
        console.log(err)
      }

      this.loading = false
    }
  }
}
</script>